.form {
    background-color: white;
    color: black;
    position: absolute;
    width: 900px;
	/* height: 35rem; */
	top: 10%;
	left: 25%;
    border-radius: 10px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    padding: 2rem;
    /* min-height: 89vh; */
    text-align: center;
}

.form label {
    margin: 0 1rem;
}

.form h2 {
    margin-top: 5rem;
	color: black;
	font-size: 40px;
	align-self: center;
}

.form__input {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #edf5f3;
	margin: 5px 0;
	font-size: 14px;
}

.form__action-buttons {
    display: inline-flex;
    color: #0095CE;
}

.form__select *:hover {
    cursor:pointer;
    background-color: #0095CE;
}

.form__select {
	border: 3px solid black;
	width: 100%;
	min-width: 100px;
	text-align: center;
    height: 100%;
}

.form__select[name="roles"] {
	overflow-y: hidden;
}

.form img {
	position: absolute;
	top: 10%;
	left: 50%;
	transform: translateX(-50%);
	width: 120px;
    height: 40px;
}

div {
    margin: 0.5rem 0;
}

.div_selectBoxes {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.div_selectBoxes div {
	width: 90%;
}