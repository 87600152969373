.brightness__dropdown {
    position: sticky;
    
  }
  .brightness__btn{
    padding: 1.5rem 5rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #0095CE;
    text-shadow: 0px 0px 0px #7CACDE;
    box-shadow: 0px 3px 7px #003447;
    padding: 10px 27px;
    border-radius: 10px;
    border: 0px solid #3866A3;
    background: #EEEEEE;
    transition: background-color 0.5s linear 0s;
    margin: 1rem;
  }
  .brightness__btn:hover{
    color: #0095CE !important;
    background: #96CCE5;
  }
  .brightness__btn:active{
    background-color: red;
  }
  
  .menu {
    position: absolute;
    
    
  
    list-style-type: none;
    margin: 5px 0;
    padding: 0;
    
  
    border: 1px solid #fff;
    width: 150px;
    
  }
  
  .menu > li {
    margin: 0;
    color: #EEEEEE;
    
    
  
    background-color: #0095CE;
  }
  
  .menu > li:hover {
    background-color: #3866A3;
  }
  
  .menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
    
  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }

  