.home__page{
    color: #fff;
}
    .user__message{
        margin: auto;
        width: 40%;
        padding: 10px;
    }
    #login{
        margin-top: 30px;
    }