.welcome {
    text-align: center;
    /* border: solid red; */
    line-height: 2;
    height: 90vh;
}

.welcome h1 {
    font-size: 3em;
}

.menuItem {
    font-size: 1.5em;
    line-height: 4;
}

.menuItem:hover {
    cursor: pointer;
    background: #008BC4;
}