.login_container {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login_form_container {
	position: relative;
	color: black;
	width: 900px;
	height: 500px;
	display: flex;
	border-radius: 10px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.login_form_container img{
	position: absolute;
	top: 10%;
	left: 50%;
	transform: translateX(-50%);
	width: 120px;
    height: 60px;
}

.left {
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form_container h1 {
	font-size: 40px;
	margin-top: 0;
}

.input {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #edf5f3;
	margin: 5px 0;
	font-size: 14px;
}

.error_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.right {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #3bb19b;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.right h1 {
	margin-top: 0;
	color: white;
	font-size: 40px;
	align-self: center;
}

.white_btn,
.green_btn {
	padding: 1.5rem 5rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #0095CE;
    text-shadow: 0px 0px 0px #7CACDE;
    box-shadow: 0px 3px 7px #003447;
    padding: 10px 27px;
    border-radius: 10px;
    border: 0px solid #3866A3;
    background: #EEEEEE;
    transition: background-color 0.5s linear 0s;
    margin: 1rem;
}

.green_btn {
	background-color: #0095CE;
	color: white;
	margin: 10px;
}