.brightness__dropdown {
    position: relative;
    
  }
  .brightness__btn{
    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    background: grey;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 1rem;
    transition: background-color 0.5s linear 0s;
  }
  .brightness__btn:hover{
    background-color: blue;
  }
  .brightness__btn:active{
    background-color: red;
  }
  
  .menu {
    position: absolute;
  
    list-style-type: none;
    margin: 5px 0;
    padding: 0;
  
    border: 1px solid grey;
    width: 150px;
    
  }
  
  .menu > li {
    margin: 0;
  
    background-color: black;
  }
  
  .menu > li:hover {
    background-color: lightgray;
  }
  
  .menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }

  