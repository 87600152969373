.digital__container {
    padding: 2rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 200;
    }
    .message__container {
        padding: 2rem;
        margin-bottom: 1rem;
        display: grid;
        background-color: 0095CE;
        border-style: solid;
        border-width: 1px;
        border-radius: 10px;
        text-align: center;
        }
        .digital__btn{
            padding: 1.5rem 5rem;
            color: #fff;
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            color: #0095CE;
            text-shadow: 0px 0px 0px #7CACDE;
            box-shadow: 0px 3px 7px #003447;
            padding: 10px 27px;
            border-radius: 10px;
            border: 0px solid #3866A3;
            background: #EEEEEE;
            transition: background-color 0.5s linear 0s;
            margin: 1rem;
        }
        .digital2__btnClassOn{
            color: #fff;
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            background: red;
            text-shadow: 0px 0px 0px #7CACDE;
            box-shadow: 0px 3px 7px #003447;
            padding: 10px 27px;
            border-radius: 10px;
            border: 0px solid #3866A3;
            cursor: pointer;
            margin: 1rem;
        }
        .digital2__btnClassOff{
            color: #fff;
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            background: green;
            text-shadow: 0px 0px 0px #7CACDE;
            box-shadow: 0px 3px 7px #003447;
            padding: 10px 27px;
            border-radius: 10px;
            border: 0px solid #3866A3;
            cursor: pointer;
            margin: 1rem;
        }
        .digital__btn:hover{
            color: #0095CE !important;
            background: #96CCE5;
        }
        .digital__btn:active{
            background-color: red;
        }